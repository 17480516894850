.ck-editor__editable {
   height:  calc(100vh - 280px);
   border: none
}
/* p {
    color: red
} */
.cke_chrome{
    border: nones
}
.ck.ck-editor__editable_inline {
    border: none;
}
.cke_focus #cke_1_contents {
    border: none !important;
}
.ck .ck-editor__main > .ck-editor__editable {
    background: #171617;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: none !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none !important;
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border: none !important;
    
}
.ck-editor__main p,h3,h4 {
    color: #fff ;
}