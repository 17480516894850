.wrapper-class {
    padding: 1rem;
    /* border: 1px solid #ccc; */
}
.editor-class {
    /* background-color: lightgray; */
    /* padding: 1rem; */
    /* border: 1px solid #ccc; */
    color: #fff;
    font-weight: none;
    font-style: none;
    overflow-y: scroll;
    /* background-color: red; */
    height: 100%;
	min-height: calc(100vh - 345px);


}
.toolbar-class {
    /* border: 1px solid #ccc; */
    /* background: #171617; */
}
.demo-option-custom{
    background-color: #171617;
    
}
.demo-option-custom-hide {
    display: none;
}
.public-DraftStyleDefault-block {
    margin: 0;
}
.demo-option-custom-wide{
    /* background: green; */
}
.demo-dropdown-custom {
    /* background: yellow */
}