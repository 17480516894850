.react-datepicker__input-container input {
	display: block;
	width: 100%;
}

.react-datepicker__input-container {
	display: block !important;
	width: none !important;
}

.react-datepicker-wrapper {
	width: 100% !important;
	width: 49% !important;
}

.datePicker {
	width: 100% !important;
}
