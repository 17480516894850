/* Set content for font-families */
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
	font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
	font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
	font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
	font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
	font-family: Helvetica, sans-serif;
}
.ql-font-Inter,
.ql-font span[data-value="Inter"]::before {
	font-family: "Inter", sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
	font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

/* Set content for sizes */
.ql-size-extra-small,
.ql-size span[data-value="extra-small"]::before {
	font-size: 13px !important;
}
.ql-size-small,
.ql-size span[data-value="small"]::before {
	font-size: 14px !important;
}
.ql-size-medium,
.ql-size span[data-value="medium"]::before {
	font-size: 18px !important;
}
.ql-size-large,
.ql-size span[data-value="large"]::before {
	font-size: 20px !important;
}

.ql-editor {
	min-height: 50vh;
	font-size: 16px;
}
.App .ql-snow .ql-tooltip.ql-flip {
	width: auto;
	left: 17px !important;
	white-space: normal;
}

/* my styles */
#t1 {
	height: 60px;
}
.quill {
	height: calc(100vh - 300px);
	overflow: scroll;
}
.quill::-webkit-scrollbar {
	display: none;
}
.quill {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.ql-toolbar.ql-snow {
	border: none;
}
.ql-container.ql-snow {
	border: none;
	font-weight: none;
	font-style: none;
}
.ql-editor {
	font-weight: none !important;
	font-style: none !important;
}
.ql-editor strong {
	font-weight: bold;
}
.ql-editor strong em {
	font-weight: bold;
	font-style: italic;
}
.ql-editor strong em u {
	font-weight: bold;
	font-style: italic;
	text-decoration: underline;
}
.ql-editor strong u {
	font-weight: bold;
	text-decoration: underline;
}
.ql-editor em {
	font-style: italic;
}
.ql-editor em u {
	text-decoration: underline;
	font-style: italic;
}
.ql-editor u {
	text-decoration: underline;
}
.ql-snow .ql-editor h3 {
	font-size: 20px !important;
	color: #fff;
}

@page {
	margin: 1in;
}

@media print {
	body {
		background: none;
	}
	.container .ql-editor {
		width: 6.5in;
		height: 9in;
		padding: 0;
		margin: 0;
		box-shadow: none;
		align-self: flex-start;
	}
	.container .ql-toolbar.ql-snow {
		display: none;
	}
}
