/* input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	height: 18px;
	width: 18px;
	background-color: #203035;
	cursor: pointer;
	border-radius: 5px;
}
input[type="checkbox"]:after {
	background-color: red;
	height: 10px;
	width: 10px;
	content: "hh";
	font-size: 20px;
} */
/* @import "~pretty-checkbox/src/pretty.scss"; */
.parentHover {
	position: relative;
}
.HoverButton {
	display: inline-block;
	cursor: pointer;
}
.HoverButton:hover .test {
	display: block;
}
.test {
	display: none;
}
.hoverHide {
	cursor: pointer;
}
