:not(span)  {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	text-decoration: none;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
img {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}
*::-moz-selection {
}
*::selection {
}
/* *::-webkit-scrollbar {
	width: 0.3rem;
}
*::-webkit-scrollbar-thumb:hover {
	background: #b7b7b7;
} */
ol,
ul {
	list-style: none;
}
blockquote {
	quotes: none;
}
blockquote:before,
blockquote:after {
	content: "";
	content: none;
}
del {
	text-decoration: line-through;
}
body {
	font-size: 1.0625rem;
	color: #333;
	background: #222;
	overflow-x: hidden;
	min-width: 20rem;
	position: relative;
	font-family: "poppinsregular";

}
input,
textarea,
select {
	font-family: "poppinsregular";
	background: unset;
	border: unset;
}
textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="text"],
input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}

*:focus {
	outline: none;
}
a {
	color: #333;
}
.submit:hover {
	filter: alpha(opacity=85);
	-moz-opacity: 0.85;
	-khtml-opacity: 0.85;
	opacity: 0.85;
}
.thumb {
	display: block;
	width: 100%;
}
.thumb img {
	display: block;
	width: 100%;
}
p {
	line-height: 1.6em;
	font-size: 1rem;
	color: #fff;
}
br.clear {
	clear: both;
	line-height: 0;
}
.slick-arrow {
	display: none !important;
}
@font-face {
	font-family: "poppinsbold";
	src: url("../fonts/poppins-bold-webfont.woff2") format("woff2"),
		url("../fonts/poppins-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "poppinsmedium";
	src: url("../fonts/poppins-medium-webfont.woff2") format("woff2"),
		url("../fonts/poppins-medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "poppinsregular";
	src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
		url("../fonts/poppins-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "poppinssemibold";
	src: url("../fonts/poppins-semibold-webfont.woff2") format("woff2"),
		url("../fonts/poppins-semibold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "product_sansbold";
	src: url("../fonts/product_sans_bold-webfont.woff") format("woff2"),
		url("../fonts/product_sans_bold-webfont.woff2") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "product_sansregular";
	src: url("../fonts/product_sans_regular-webfont.woff2") format("woff2"),
		url("../fonts/product_sans_regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ramblabold";
	src: url("../fonts/rambla-bold-webfont.woff2") format("woff2"),
		url("../fonts/rambla-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ramblaregular";
	src: url("../fonts/rambla-regular-webfont.woff2") format("woff2"),
		url("../fonts/rambla-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
